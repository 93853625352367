.Catalog {
    width: 100%;
    margin: 16px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

@media (min-width: 600px) {
    .Catalog {
        flex-direction: row;
    }
}

.CatalogFilters {
    padding-left: 8px;
    padding-right: 8px;
}

.CatalogCategories {
    min-width: 250px;
}

.CatalogCategories h2 {
    padding-bottom: 16px;
}

.CatalogCategories .CheckBox {
    padding-bottom: 8px;
}

.CatalogPage {
    padding: 4px 16px;
    display: flex;
    flex-direction: column;
}

.CatalogPageDisabled {
    opacity: 0.5;
    pointer-events: none;
}

.CatalogPageToolbar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
}

.CatalogPageToolbar .CurrencySelector {
    margin-right: 16px;
}

.CatalogPageSearchBar {
    position: relative;
    width: 100%;
    max-width: 360px;
}

.CatalogPageSearchBar svg {
    position: absolute;
    margin-left: 8px;
    top: 50%;
    transform: translateY(-50%);
}

.CatalogPageSearchBar input {
    width: 100%;
    max-width: 360px;
    padding-left: 32px;
}

.CatalogCardsContainer {
    display: grid;
    gap: 1rem;
}

.CatalogPagination {

}

.CatalogPagination ul {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    padding-left: 0;
    list-style-type: none;
}

.CatalogPagination a {
    padding: 16px;
    user-select: none;
}

.CatalogPagination a:hover {
    background: #f5f5f5;
}

@media (min-width: 850px) {
    .CatalogPage {
        width: 60%;
    }

    .CatalogPageToolbar {
        flex-direction: row;
    }

    .CatalogCardsContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1150px) {
    .CatalogCardsContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}
