.ProductVariantSelector {
    width: fit-content;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 4px;
    padding: 2px;
    margin: 0;
}

.ProductVariantSelectorItem {
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 2px;
}

.ProductVariantSelectorItemColor {
    width: 1.65rem;
    height: 1.65rem;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.ProductVariantSelectorItem.Active {
    outline: 1px solid #000;
    position: relative;
    border-radius: 8px;
}

.ProductVariantSelectorItem:hover {
    cursor: pointer;
}

.ProductVariantSelectorItemTitle {
    margin-left: 4px;
    margin-right: 4px;
}
