.PageHeadingPlaceholder {
    width: 100%;
    height: 83px;
    background: black;
}

@media only screen and (min-width: 560px) {
    .PageHeadingPlaceholder {
        height: 7.55rem;
    }
}
