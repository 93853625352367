.Profile {
    max-width: 73.125rem;
    margin: auto;
    padding: 16px;
}

.Collapsible {
    cursor: default;
    max-width: 700px;
}

.Collapsible__contentInner {
    padding: 8px;
    border: 1px solid lightgray;
    border-top: 0;
}

.Collapsible__trigger {
    display: flex;
    border: 1px solid gray;
    padding: 8px;
    margin-top: 8px;
}

.Collapsible__trigger:hover {
    background-color: #f6f6f6;
}

.OrderHeader {
    display: flex;
    flex-direction: row;
}
