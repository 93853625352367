.CartItems {
    padding: 1rem 1rem;
    font-weight: 100;
}

.CartItemsTotals .CartItemsTotalsItem {
    float: right;
    clear: both;
    width: 100%;
    margin-bottom: 10px;
}

.CartItemsTotals label {
    float: left;
    clear: both;
    width: 79%;
    text-align: right;
}

.CartItemsTotals .CartItemsTotalsValue {
    float: right;
    width: 21%;
    text-align: right;
}

.CartItemsCheckout {
    float: right;
    margin-top: 20px;
}

@media screen and (max-width: 650px) {

    .CartItems {
        margin: 0;
        padding-top: 20px;
    }
}

@media screen and (max-width: 350px) {

    .CartItemsTotals .CartItemsTotalsItem label {
        width: 60%;
    }

    .CartItemsTotals .CartItemsTotalsItem .CartItemsTotalsValue {
        width: 40%;
    }
}
