.NoItemsLayout {

}

.NoItemsLayoutMessage {
    max-width: 512px;
    text-align: center;
    vertical-align: middle;
    margin-top: 36px;
}

.NoItemsLayoutExternalTiles {
    max-width: 512px;
}
