.ProductViewCurrencySelector {
    margin-bottom: 16px;
}

.ProductViewLinks {
    display: flex;
    justify-content: left;
    margin-bottom: 16px;
}

.ProductStoryText {
    white-space: pre-line;
    color: #888
}

.ProductStoryText p {
    font-size: .825rem;
    line-height: 1.5rem;
}

@media only screen and (min-width: 560px) {

    .ProductStoryText p {
        font-size: 1rem;
        line-height: 1.75rem
    }

}

@media only screen and (min-width: 1024px) {

    .ProductStoryText p {
        font-size: 1rem;
        line-height: 1.65rem
    }
}
