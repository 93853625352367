.FeedbackForm {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.FeedbackAlert {
    margin-top: 20px;
}

.l-feedback {
    width: 100%
}

.l-feedback input, .l-feedback textarea {
    width: 100%
}

.l-feedback .feedback_inputs {
    width: 100%;
    max-width: 400px;
}

.l-feedback .feedback_inputs input {
    width: 100%
}

.feedback {

}

.feedback_title {
    margin-bottom: 1.5rem
}

.feedback input {
    margin-bottom: .75rem;
    line-height: 3rem;
    border-width: 2px;
    border-style: solid;
    padding: 0 10px;
    border-color: #f0f0f0;
}

.feedback textarea {
    min-height: 120px;
    line-height: 1.5rem;
    margin-bottom: .75rem;
    padding: .5rem;
    border-width: 2px;
    border-color: #f0f0f0;
}

.feedback .success {
    background-color: #f0f0f0;
    border-left: 3px solid #f60;
    padding: 1rem .825rem;
    margin-bottom: 1.5rem;
    width: 20rem
}

.feedback .alert {
    background-color: #f0f0f0;
    border-left: 3px solid #f60;
    font-size: .875rem;
    line-height: 1.625rem;
    display: none;
    padding-left: .5rem;
    margin-bottom: 4px
}

.feedback_title {
    margin-top: 2.5rem;
    margin-bottom: 1rem
}

.feedback_text .InputField {
    height: 250px;
}

.feedback_text textarea {
    height: 100%;
    resize: none;
}

@media only screen and (min-width: 640px) {
    .FeedbackForm input, .feedback textarea {
        margin-bottom: 1rem
    }

    .feedback {
        padding-left: 0;
        padding-right: 0
    }

    .feedback_title {
        margin-top: 0
    }

    .feedback_text {
        height: auto;
        padding-bottom: 1rem
    }
}

@media only screen and (min-width: 768px) {
    .feedback_area {
        display: flex
    }

    .feedback_area .feedback_inputs, .feedback_area .feedback_text {
        width: 100%
    }

    .FeedbackForm .feedback_text {
        margin-left: 1rem
    }

    .feedback_text .InputField {
        height: 100%;
    }
}
