.Flex {
    display: flex;
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
}

.Center {
    justify-content: center;
    align-items: center;
}
