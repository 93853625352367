.NavIcons {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: right;
    padding: 8px;
}

.NavIcon {
    margin: 8px;
    color: white;
}

.NavIcon:hover {
    opacity: 0.5;
}
