.OrderForm {
    padding: 1rem 1rem;
}

.OrderFormShippingMethods {
    padding-top: 20px;
    min-width: 250px;
}

.OrderFormPaymentMethods {
    padding-top: 20px;
    min-width: 250px;
}

.OrderFormShippingMethods h2, .OrderFormPaymentMethods h2 {
    padding-bottom: 16px;
}

.OrderFormShippingMethods .CheckBox, .OrderFormPaymentMethods .CheckBox {
    padding-bottom: 8px;
}

.OrderFormCheckout {
    float: left;
    margin-top: 20px;
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 10px;
}
