.CartGroup:before, .CartGroup:after {
    content: '';
    display: table;
}

.CartGroup:after {
    clear: both;
}

.CartGroup {
    zoom: 1;
}
