.CheckBox {
    display: flex;
    flex-direction: row;
}

.CheckBox input[type="checkbox"] {
    appearance: none;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.CheckBox input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: currentColor;
}

.CheckBox input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.CheckBox label {
    padding-left: 4px;
}
