.CartItemView {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
}

.CartItemView .CartItemViewImage {
    text-align: center;
}

.CartItemViewImage {
    float: left;
    width: 20%;
}

.CartItemViewImage img {
    width: 100px;
    aspect-ratio: 2;
    object-fit: contain;
}

.CartItemViewTitle {
    margin-right: 20px;
    float: left;
    width: 37%;
}

.CartItemViewPrice {
    float: left;
    width: 12%;
    text-align: right;
    margin-right: 10px;
}

.CartItemViewQuantity {
    float: left;
    display: flex;
    align-items: center;
}

.CartItemViewQuantity input {
    width: 60px;
}

.CartItemViewQuantity small {
    margin-right: 10px;
}

.CartItemViewRemoval {
    float: left;
    width: 19%;
    margin-left: 20px;
}

.CartItemViewLinePrice {
    float: left;
    width: 12%;
    text-align: right;
}

@media screen and (max-width: 650px) {

    .CartItemView {
        display: block;
    }

    .CartItemViewImage {
        width: auto;
    }

    .CartItemViewImage img {
        margin: 0 0 10px 10px;
    }

    .CartItemViewTitle {
        margin-top: 10px;
        width: auto;
    }

    .CartItemViewPrice {
        clear: both;
        width: 70px;
        margin-top: 10px;
    }

    .CartItemViewRemoval {
        width: auto;
        margin-top: 4px;
    }

    .CartItemViewLinePrice {
        float: right;
        width: 70px;
        margin-top: 6px;
    }
}

@media screen and (max-width: 350px) {

    .CartItemView {
        display: block;
    }

    .CartItemViewRemoval {
        float: right;
    }

    .CartItemViewLinePrice {
        float: right;
        clear: left;
        width: auto;
        margin-top: 10px;
    }
}
