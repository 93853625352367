.CatalogCard {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem;
    text-align: center;
    position: relative;
}

.CatalogCard:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.CatalogCard a img {
    object-fit: contain;
    height: 200px;
}

.CatalogCardOverlay {
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    opacity: 0;
    z-index: -1;
    display: flex;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(5px);
    transition: all .48s ease
}

.CatalogCardOverlay .ProductVariantSelector {
    margin-bottom: 10px;
}

.CatalogCard:hover .CatalogCardOverlay {
    opacity: 1;
    z-index: 1
}

.CatalogCardProperties {
    display: flex;
    flex-direction: row;
}

.CatalogCardPropertiesLeft {
    width: 100%;
    text-align: left;
}

.CatalogCardPropertiesRight {
    white-space: nowrap;
}
