.NavBarRest {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.NavBarLogo {
    width: auto;
    height: 4rem;
    aspect-ratio: 1;
}

@media (min-width: 600px) {
    .NavBarLogo {
        height: 5rem;
    }
}

@media (min-width: 1200px) {
    .NavBarLogo {
        height: 6rem;
    }
}
