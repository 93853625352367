.CurrencySelector {
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0;
    margin-bottom: 0;
}

.CurrencySelector h4 {
    margin-top: .25rem;
    font-size: 1.25rem;
    line-height: 1.25rem;
    margin-right: .35rem;
}

.CurrencySelectorItem {
    margin-left: .625rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
}

.CurrencySelectorItem.Active {
    padding: 3px;
    border: 1px solid #000;
    position: relative;
    border-radius: 8px;
}

.CurrencySelectorItem:hover {
    cursor: pointer;
}

.CurrencySelectorItemTitle {
    margin-left: 4px;
    margin-right: 4px;
}
