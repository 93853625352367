.InputField {

}

.InputField small {
    color: red;
}

.InputFieldError {

}

.InputFieldError input {
    border-color: red;
}

.InputFieldError textarea {
    border-color: red;
}
